export const GET_ALL_ASSESSMENT_COMMENTS_REQUEST= "GET_ALL_ASSESSMENT_COMMENTS_REQUEST";
export const GET_ALL_ASSESSMENT_COMMENTS_SUCCESS= "GET_ALL_ASSESSMENT_COMMENTS_SUCCESS";
export const GET_ALL_ASSESSMENT_COMMENTS_FAILED = "GET_ALL_ASSESSMENT_COMMENTS_FAILED";

export const GET_ASSESSMENT_COMMENT_BY_ID_REQUEST = "GET_ASSESSMENT_COMMENT_BY_ID_REQUEST";
export const GET_ASSESSMENT_COMMENT_BY_ID_SUCCESS =  "GET_ASSESSMENT_COMMENT_BY_ID_SUCCESS";
export const GET_ASSESSMENT_COMMENT_BY_ID_FAILED = "GET_ASSESSMENT_COMMENT_BY_ID_FAILED";

export const POST_ASSESSMENT_COMMENT_REQUEST = "POST_ASSESSMENT_COMMENT_REQUEST";
export const POST_ASSESSMENT_COMMENT_SUCCESS = "POST_ASSESSMENT_COMMENT_SUCCESS";
export const POST_ASSESSMENT_COMMENT_FAILED = "POST_ASSESSMENT_COMMENT_FAILED";

export const UPDATE_ASSESSMENT_COMMENT_BY_ID_REQUEST = "UPDATE_ASSESSMENT_COMMENT_BY_ID_REQUEST";
export const UPDATE_ASSESSMENT_COMMENT_BY_ID_SUCCESS = "UPDATE_ASSESSMENT_COMMENT_BY_ID_SUCCESS";
export const UPDATE_ASSESSMENT_COMMENT_BY_ID_FAILED = "UPDATE_ASSESSMENT_COMMENT_BY_ID_FAILED";

export const DELETE_ASSESSMENT_COMMENT_BY_ID_REQUEST = "DELETE_ASSESSMENT_COMMENT_BY_ID_REQUEST";
export const DELETE_ASSESSMENT_COMMENT_BY_ID_SUCCESS = "DELETE_ASSESSMENT_COMMENT_BY_ID_SUCCESS";
export const DELETE_ASSESSMENT_COMMENT_BY_ID_FAILED = "DELETE_ASSESSMENT_COMMENT_BY_ID_FAILED";

export const RESET_ASSESSMENT_COMMENT_CREATION_ERROR = "RESET_ASSESSMENT_COMMENT_CREATION_ERROR";

// Assessment Comment Attributes
export const GET_ALL_ASSESSMENT_COMMENT_ATTRIBUTES_REQUEST= "GET_ALL_ASSESSMENT_COMMENT_ATTRIBUTES_REQUEST";
export const GET_ALL_ASSESSMENT_COMMENT_ATTRIBUTES_SUCCESS= "GET_ALL_ASSESSMENT_COMMENT_ATTRIBUTES_SUCCESS";
export const GET_ALL_ASSESSMENT_COMMENT_ATTRIBUTES_FAILED = "GET_ALL_ASSESSMENT_COMMENT_ATTRIBUTES_FAILED";

export const GET_ASSESSMENT_COMMENT_ID_REQUEST= "GET_ASSESSMENT_COMMENT_ID_REQUEST";
export const GET_ASSESSMENT_COMMENT_ID_SUCCESS = "GET_ASSESSMENT_COMMENT_ID_SUCCESS";
export const GET_ASSESSMENT_COMMENT_ID_FAILED = "GET_ASSESSMENT_COMMENT_ID_FAILED";

export const RESET_ASSESSMENT_COMMENT_ID= "RESET_ASSESSMENT_COMMENT_ID";

export const GET_COMMENT_ATTRIBUTE_BY_ID_REQUEST= "GET_COMMENT_ATTRIBUTE_BY_ID_REQUEST";
export const GET_COMMENT_ATTRIBUTE_BY_ID_SUCCESS = "GET_COMMENT_ATTRIBUTE_BY_ID_SUCCESS";
export const GET_COMMENT_ATTRIBUTE_BY_ID_FAILED = "GET_COMMENT_ATTRIBUTE_BY_ID_FAILED";

export const POST_ASSESSMENT_COMMENT_ATTRIBUTES_REQUEST = "POST_ASSESSMENT_COMMENT_ATTRIBUTES_REQUEST";
export const POST_ASSESSMENT_COMMENT_ATTRIBUTES_SUCCESS = "POST_ASSESSMENT_COMMENT_ATTRIBUTES_SUCCESS";
export const POST_ASSESSMENT_COMMENT_ATTRIBUTES_FAILED = "POST_ASSESSMENT_COMMENT_ATTRIBUTES_FAILED";

export const UPDATE_ASSESSMENT_COMMENT_ATTRIBUTE_BY_ID_REQUEST = "UPDATE_ASSESSMENT_COMMENT_ATTRIBUTE_BY_ID_REQUEST";
export const UPDATE_ASSESSMENT_COMMENT_ATTRIBUTE_BY_ID_SUCCESS = "UPDATE_ASSESSMENT_COMMENT_ATTRIBUTE_BY_ID_SUCCESS";
export const UPDATE_ASSESSMENT_COMMENT_ATTRIBUTE_BY_ID_FAILED = "UPDATE_ASSESSMENT_COMMENT_ATTRIBUTES_BY_ID_FAILED";

export const DELETE_ASSESSMENT_COMMENT_ATTRIBUTES_BY_ID_REQUEST = "DELETE_ASSESSMENT_COMMENT_ATTRIBUTES_BY_ID_REQUEST";
export const DELETE_ASSESSMENT_COMMENT_ATTRIBUTES_BY_ID_SUCCESS = "DELETE_ASSESSMENT_COMMENT_ATTRIBUTES_BY_ID_SUCCESS";
export const DELETE_ASSESSMENT_COMMENT_ATTRIBUTES_BY_ID_FAILED = "DELETE_ASSESSMENT_COMMENT_ATTRIBUTES_BY_ID_FAILED";

export const RESET_COMMENT_ATTRIBUTE_CREATION_ERROR = "RESET_COMMENT_ATTRIBUTE_CREATION_ERROR";

export const GET_ATTRIBUTE_COLORS_REQUEST = "GET_ATTRIBUTE_COLORS_REQUEST";
export const GET_ATTRIBUTE_COLORS_SUCCESS = "GET_ATTRIBUTE_COLORS_SUCCESS";
export const GET_ATTRIBUTE_COLORS_FAILED = "GET_ATTRIBUTE_COLORS_FAILED";

export const GET_COMMENT_SUB_ATTRIBUTE_BY_ID_REQUEST = "GET_COMMENT_SUB_ATTRIBUTE_BY_ID_REQUEST";
export const GET_COMMENT_SUB_ATTRIBUTE_BY_ID_SUCCESS = "GET_COMMENT_SUB_ATTRIBUTE_BY_ID_SUCCESS";
export const GET_COMMENT_SUB_ATTRIBUTE_BY_ID_FAILED = "GET_COMMENT_SUB_ATTRIBUTE_BY_ID_FAILED";

export const GET_SUB_ATTRIBUTE_ID_REQUEST = "GET_SUB_ATTRIBUTE_ID_REQUEST";
export const GET_SUB_ATTRIBUTE_ID_SUCCESS = "GET_SUB_ATTRIBUTE_ID_SUCCESS";
export const GET_SUB_ATTRIBUTE_ID_FAILED = "GET_SUB_ATTRIBUTE_ID_FAILED";

export const GET_SUB_ATTRIBUTE_BY_ID_REQUEST = "GET_SUB_ATTRIBUTE_BY_ID_REQUEST";
export const GET_SUB_ATTRIBUTE_BY_ID_SUCCESS = "GET_SUB_ATTRIBUTE_BY_ID_SUCCESS";
export const GET_SUB_ATTRIBUTE_BY_ID_FAILED = "GET_SUB_ATTRIBUTE_BY_ID_FAILED";

export const DESELECT_SUB_ATTRIBUTE = "DESELECT_SUB_ATTRIBUTE";

export const CREATE_SUB_ATTRIBUTE_REQUEST = "CREATE_SUB_ATTRIBUTE_REQUEST";
export const CREATE_SUB_ATTRIBUTE_SUCCESS = "CREATE_SUB_ATTRIBUTE_SUCCESS";
export const CREATE_SUB_ATTRIBUTE_FAILED = "CREATE_SUB_ATTRIBUTE_BY_FAILED";

export const UPDATE_SUB_ATTRIBUTE_REQUEST = "UPDATE_SUB_ATTRIBUTE_REQUEST";
export const UPDATE_SUB_ATTRIBUTE_SUCCESS = "UPDATE_SUB_ATTRIBUTE_SUCCESS";
export const UPDATE_SUB_ATTRIBUTE_FAILED = "UPDATE_SUB_ATTRIBUTE_BY_FAILED";

export const GET_ATTRIBUTE_RESULTS_REQUEST = "GET_ATTRIBUTE_RESULTS_REQUEST";
export const GET_ATTRIBUTE_RESULTS_SUCCESS = "GET_ATTRIBUTE_RESULTS_SUCCESS";
export const GET_ATTRIBUTE_RESULTS_FAILED = "GET_ATTRIBUTE_RESULTS_FAILED";