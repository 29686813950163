import { combineReducers } from "redux";
import * as types from './assessmentCommentTypes';

const getAssessmentCommentsLoading = (state = true, action) => {
    switch (action.type) {
        case types.GET_ALL_ASSESSMENT_COMMENTS_REQUEST: {
            return true;
        }
        case types.GET_ALL_ASSESSMENT_COMMENTS_SUCCESS:
        case types.GET_ALL_ASSESSMENT_COMMENTS_FAILED: {
            return false;
        }
        default:
            return state
    }
};

const selectedAssessmentCommentIdLoading = (state = true, action) => {
    switch (action.type) {
        case types.GET_ASSESSMENT_COMMENT_ID_REQUEST: {
            return true;
        }

        case types.GET_ASSESSMENT_COMMENT_ID_SUCCESS:
        case  types.GET_ASSESSMENT_COMMENT_ID_FAILED: {
            return false;
        }

        default:
            return state;
    }
};

const selectedAssessmentCommentLoading = (state = true, action) => {
    switch (action.type) {
        case types.GET_ASSESSMENT_COMMENT_BY_ID_REQUEST: {
            return true;
        }

        case types.GET_ASSESSMENT_COMMENT_BY_ID_SUCCESS:
        case types.GET_ASSESSMENT_COMMENT_BY_ID_FAILED: {
            return false;
        }

        default:
            return state;
    }
};

const getAssessmentCommentAttributesLoading = (state = true, action) => {
    switch (action.type) {
        case types.GET_ALL_ASSESSMENT_COMMENT_ATTRIBUTES_REQUEST: {
            return true;
        }

        case types.GET_ALL_ASSESSMENT_COMMENT_ATTRIBUTES_SUCCESS:
        case  types.GET_ALL_ASSESSMENT_COMMENT_ATTRIBUTES_FAILED: {
            return false;
        }

        default:
            return state;
    }
};

const selectedAttributeLoading = (state = true, action) => {
    switch (action.type) {
        case types.GET_COMMENT_ATTRIBUTE_BY_ID_REQUEST: {
            return true;
        }

        case types.GET_COMMENT_ATTRIBUTE_BY_ID_SUCCESS:
        case types.GET_COMMENT_ATTRIBUTE_BY_ID_FAILED: {
            return false;
        }

        default:
            return state;
    }
};

const loadingAttributeColors = (state = false, action) => {
    switch (action.type) {
        case types.GET_ATTRIBUTE_COLORS_REQUEST: {
            return true;
        }

        case types.GET_ATTRIBUTE_COLORS_SUCCESS:
        case types.GET_ATTRIBUTE_COLORS_FAILED: {
            return false;
        }

        default: return state;
    }
};

const loadingCommentSubAttributes = (state = false, action) => {
    switch (action.type) {
        case types.GET_COMMENT_SUB_ATTRIBUTE_BY_ID_REQUEST: {
            return true;
        }

        case types.GET_COMMENT_SUB_ATTRIBUTE_BY_ID_SUCCESS:
        case types.GET_COMMENT_SUB_ATTRIBUTE_BY_ID_FAILED: {
            return false;
        }

        default: return state;
    }
};

const loadingSubAttribute = (state = false, action) => {
    switch (action.type) {
        case types.GET_SUB_ATTRIBUTE_BY_ID_REQUEST: {
            return true;
        }

        case types.GET_SUB_ATTRIBUTE_BY_ID_SUCCESS:
        case types.GET_SUB_ATTRIBUTE_BY_ID_FAILED: {
            return false;
        }

        default: return state;
    }
};

const loadingAttributeResults = (state = false, action) => {
    switch (action.type) {
        case types.GET_ATTRIBUTE_RESULTS_REQUEST: {
            return true;
        }

        case types.GET_ATTRIBUTE_RESULTS_SUCCESS:
        case types.GET_ATTRIBUTE_RESULTS_FAILED: {
            return false;
        }

        default: return state;
    }
};

export default combineReducers({
    getAssessmentCommentsLoading,
    selectedAssessmentCommentIdLoading,
    selectedAssessmentCommentLoading,
    getAssessmentCommentAttributesLoading,
    selectedAttributeLoading,
    loadingAttributeColors,
    loadingCommentSubAttributes,
    loadingSubAttribute,
    loadingAttributeResults,
});