import {connect} from "react-redux";
import {Container, DeleteButtonModal, FormEditor, RunDashedLogo, Title} from "../../shared";
import {ActionContext} from "../../../contexts";
import React, {useContext, useEffect, useState} from "react";
import useStatusColor from "../../../utils/useStatusSelector";
import {Link, useHistory, useParams} from "react-router-dom";
import {snackbarActions} from "../../shared/Snackbar";
import {Box, FormControl, FormHelperText, FormLabel, Grid, InputLabel, MenuItem, Typography} from "@material-ui/core";
import {Field, Form, Formik} from "formik";
import {number, object, string} from "yup";
import {GlobalCss} from "../../../config/GlobalCss";
import Alert from "@material-ui/lab/Alert";
import {Select, TextField} from "formik-material-ui";
import clsx from "clsx";
import Button from "../../shared/Button";
import CircularProgress from "../../shared/CircularProgress";
import {EditorState, convertToRaw, convertFromRaw} from 'draft-js';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import ClearIcon from "@material-ui/icons/Clear";
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import useStyles from "./styles";

const UpdateCommentAttribute = (props) => {
    const classes = useStyles();
    const {assessmentCommentActions} = useContext(ActionContext);
    const { getColorName ,getHexValue } = useStatusColor();
    const history = useHistory();
    const { attribute, error, loading } = props;
    const {attributeId} = useParams();
    let [descriptionEditorState, setDescriptionEditorState] = useState(EditorState.createEmpty());
    let [labels, setLabels] = useState([]);
    let [iconName, setIconName] = useState('');
    let [iconString, setIconString] = useState('');
    let [showIcon, setShowIcon] = useState(null);
    let [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
        assessmentCommentActions.getCommentAttributeById(attributeId)
    }, []);

    useEffect(() => {
        if (attribute && attribute.description) {
            try {
                const descriptionMarkToDraft = markdownToDraft(attribute.description);
                const descriptionContentState = convertFromRaw(descriptionMarkToDraft);
                setDescriptionEditorState(EditorState.createWithContent(descriptionContentState));
            } catch (error) {
                throw new Error(error);
            }
        }
        setLabels(attribute.labels);
        if (attribute.icon) {
            setShowIcon(attribute.icon);
        }
    }, [attribute]);

    let handleErrorClose = () => {
        assessmentCommentActions.resetCommentAttributeError();
    };

    useEffect(() => {
        if (error && error.length > 0) {
            setTimeout(() => {
                handleErrorClose();
            }, 3500);
        }
    }, [error]);

    const colorList = [
        'Dark Blue',
        'Yellow',
        'Blue',
        'Green'
    ];

    const renderError = (error) => {
        if (typeof error === 'string') {
            return error;
        } else if (Array.isArray(error)) {
            return error.join(', ');
        } else if (typeof error === 'object' && error !== null) {
            return JSON.stringify(error);
        }
        return '';
    };

    const handleFormSubmit = ( values ) => {
        const descriptionContentState = descriptionEditorState.getCurrentContent();
        const descriptionRawContent = convertToRaw(descriptionContentState);
        const descriptionMarkdown = draftToMarkdown(descriptionRawContent);

        let data = {
            title: values.title,
            description: descriptionMarkdown,
            labels: labels,
            score_start: values.score_start,
            score_end: values.score_end,
            color: getHexValue(values.color),
            icon: iconString,
        };

        assessmentCommentActions.updateAttribute(attribute._id, data);

        snackbarActions.showSnackbar(
            'Comment attribute was successfully updated.'
        );
        history.push(`/assessment-comment/update/${attribute.assessment_comment_id}`)
    };

    const handleDelete = (id) => {
        assessmentCommentActions.deleteCommentAttribute(id);
        history.push(`/assessment-comment/update/${attribute.assessment_comment_id}`)
    };

    const handleUpdateLabels = (value, setFieldValue) => {
        if (value) {
            setLabels([...labels, value]);
            setFieldValue('label', '')
        }
    };

    const handleEditLabel = (index, setFieldValue) => {
        const updateLabel = labels.filter((_, i) => i === index);
        setFieldValue('label', updateLabel);
        const updatedLabels = labels.filter((_, i) => i !== index);
        setLabels(updatedLabels)
    };

    const handleRemoveLabel = (index) => {
        const updatedLabels = labels.filter((_, i) => i !== index);
        setLabels(updatedLabels);
    };

    const handleIconUpload = (event) => {
        const file = event.target.files[0];

        const fileSizeKB = file.size / 1024;
        const fileType = file.type;

        if ((fileType !== 'image/svg+xml' && fileSizeKB > 200) || (fileType === 'image/svg+xml' && fileSizeKB > 200)) {
            setErrorMsg('Icon is larger than 200kbs');
            setIconName('');
            setIconString('');
            if (attribute.icon !== '') {
                setShowIcon(attribute.icon);
            } else {
                setShowIcon(null);
            }
            return;
        }

        setIconName(file.name);

        const previewIcon = URL.createObjectURL(file);
        setShowIcon(previewIcon);
        setErrorMsg('');

        const reader = new FileReader();
        reader.onloadend = () => {
            setIconString(reader.result);
        };

        reader.readAsDataURL(file);
    };

    const handleRemoveIcon = () => {
        setIconName('');
        setIconString('');
        if (attribute.icon !== '') {
            setShowIcon(attribute.icon);
        } else {
            setShowIcon(null);
        }
    };

    const handleIconDelete = () => {
        setShowIcon(null);
        setIconString('');
    };

    return (
        <Container>
            {
                loading && loading ?
                    <Box style={{display: 'flex', justifyContent: 'center'}}>
                        <CircularProgress size={72} thickness={3} color="primary"/>
                    </Box> :
                    <Grid container spacing={1}>
                        <Grid item xs={8} style={{ height: '100%', minHeight: '600px', maxHeight: 'auto' }}>
                            <div style={{margin: '0rem 3rem'}}>
                                <Formik
                                    initialValues={{
                                        title: attribute?.title,
                                        description: descriptionEditorState,
                                        label: '',
                                        score_start: attribute?.score_start,
                                        score_end: attribute?.score_end,
                                        color: getColorName(attribute?.color),
                                        icon: ''
                                    }}
                                    validator={object().shape({
                                        title: string()
                                            .trim()
                                            .matches(
                                                /^[a-zA-Z0-9\[\]]+(([',. \-\[\]][a-zA-Z0-9 \[\]])?[a-zA-Z0-9\[\]]*)*$/,
                                                "Enter a valid title"
                                            )
                                            .required('Enter a title'),
                                        score_start: number().nullable().required('Select a starting score'),
                                        score_end: number().nullable().required('Select a end score'),
                                        color: string().nullable().required('Select a color'),
                                    })}
                                    onSubmit={(values) => {
                                        handleFormSubmit(values)
                                    }}
                                >
                                    {(formik) => {
                                        let {errors, setFieldValue, touched, values} = formik;

                                        return (
                                            <Form autoComplete='off'>
                                                <GlobalCss />
                                                <div className={classes.form}>
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center'
                                                    }}>
                                                        <Title
                                                            title='Update'
                                                            text={attribute.title}/>
                                                        <DeleteButtonModal
                                                            title={`Update ${attribute.title}`}
                                                            handleDelete={() => handleDelete(attribute._id)}
                                                        />
                                                    </div>
                                                    {error ? (
                                                        <Grid item>
                                                            <Alert severity='error' onClose={handleErrorClose}>
                                                                {error}
                                                            </Alert>
                                                        </Grid>
                                                    ) : null}
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12}>
                                                            <Field
                                                                name="title"
                                                                label="Title"
                                                                placeholder="Title"
                                                                component={TextField}
                                                                variant="outlined"
                                                                required
                                                                fullWidth
                                                                autoFocus
                                                                style={{marginTop: '1rem'}}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}
                                                              style={{display: 'flex', justifyContent: 'space-between'}}>
                                                            <Field
                                                                name="score_start"
                                                                label="Starting score"
                                                                placeholder="Starting score"
                                                                component={TextField}
                                                                type='number'
                                                                variant="outlined"
                                                                required
                                                                autoFocus
                                                                style={{width: '31%'}}
                                                            />
                                                            <Field
                                                                name="score_end"
                                                                label="End score"
                                                                placeholder="End score"
                                                                component={TextField}
                                                                type='number'
                                                                variant="outlined"
                                                                required
                                                                autoFocus
                                                                style={{width: '31%'}}
                                                            />
                                                            <FormControl variant="outlined" style={{display: 'flex', width: '34%'}}>
                                                                <InputLabel htmlFor="color-label">
                                                                    Color *
                                                                </InputLabel>
                                                                <Field
                                                                    component={Select}
                                                                    name="color"
                                                                    label="Color *"
                                                                    placeholder="Color *"
                                                                    inputProps={{
                                                                        id: "color",
                                                                    }}
                                                                    required
                                                                    renderValue={(selected) => (
                                                                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <div style={{ backgroundColor: getHexValue(selected), width: 10, height: 10, marginRight: 10 }} />
                                                                            {selected}
                                                                        </Box>
                                                                    )}
                                                                >
                                                                    {colorList && colorList.map((list) => (
                                                                        <MenuItem key={list} value={list}>
                                                                            <Box style={{ backgroundColor: getHexValue(list), width: 10, height: 10, marginRight: 10 }} />
                                                                            {list}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Field>
                                                                {touched["sport"] && !!errors["sport"] && (
                                                                    <FormHelperText>
                                                                        {renderError(errors["sport"])}
                                                                    </FormHelperText>
                                                                )}
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <FormControl fullWidth>
                                                                <FormLabel style={{marginBottom: '5px'}}>Description</FormLabel>
                                                                <FormEditor editorState={descriptionEditorState} onEditorStateChange={setDescriptionEditorState} />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <div className={classes.labelsInput}>
                                                                <Field
                                                                    name="label"
                                                                    label="Labels"
                                                                    placeholder="Labels"
                                                                    component={TextField}
                                                                    variant="outlined"
                                                                    style={{width: '65%'}}
                                                                />
                                                                <button
                                                                    className={classes.btnPrimary}
                                                                    type="button"
                                                                    onClick={() => handleUpdateLabels(values.label, setFieldValue)}
                                                                >
                                                                    Update
                                                                </button>
                                                            </div>
                                                            <div style={{display: 'flex', justifyContent: 'flex-start', width: '100%'}}>
                                                                {
                                                                    labels && labels.length > 0 ? (
                                                                        labels.map((label, index) => (
                                                                            <div key={index} className={`${classes.labelItem} ${classes.labelItemSpacing}`}>
                                                                                <div style={{display: 'flex'}}>
                                                                                    <Box onClick={() => handleEditLabel(index, setFieldValue)} style={{marginRight: 4}}>
                                                                                        <CreateOutlinedIcon style={{fontSize: '0.9rem', marginTop: 1}}/>
                                                                                    </Box>
                                                                                    {label}
                                                                                </div>
                                                                                <Box onClick={() => handleRemoveLabel(index)} style={{marginTop: 'auto'}}>
                                                                                    <ClearIcon style={{fontSize: '0.9rem'}}/>
                                                                                </Box>
                                                                            </div>
                                                                        ))
                                                                    ) : null
                                                                }
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <FormLabel>Trait Icon</FormLabel>
                                                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '97%'}}>
                                                                {
                                                                    showIcon === null  ? (
                                                                        <div style={{
                                                                            border: 'dashed 1px #D8D8D8',
                                                                            width: 120,
                                                                            height: 120,
                                                                            backgroundColor: '#f3f3f3',
                                                                            borderRadius: 4,
                                                                        }}/>
                                                                    ) : (
                                                                        <div style={{display: "flex"}}>
                                                                            <img src={showIcon} alt='Icon' width={120} height={120}/>
                                                                            <div style={{display: 'flex', alignItems: 'flex-start'}}>
                                                                                {
                                                                                    iconName === '' && showIcon ? (
                                                                                        <Box onClick={handleIconDelete} style={{display: 'flex', alignItems: 'center'}}>
                                                                                            <ClearIcon style={{fontSize: '1.1rem', marginLeft: 15}}/>
                                                                                        </Box>
                                                                                    ) : null
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                            <div style={{display: 'flex', paddingTop: 10}}>
                                                                <FormLabel htmlFor='iconUpload' className={classes.iconBtn}>
                                                                    <div style={{marginTop: 8}}>
                                                                        Upload Icon
                                                                    </div>
                                                                    <input type='file' hidden name='icon' id='iconUpload' onChange={handleIconUpload}/>
                                                                </FormLabel>
                                                                <div style={{display: 'flex'}}>
                                                                    <Typography style={{
                                                                        fontSize: '1rem',
                                                                        marginTop: 15,
                                                                        marginLeft: 5,
                                                                    }}>
                                                                        {iconName}
                                                                    </Typography>
                                                                    {
                                                                        iconName.length > 0 ? (
                                                                            <Box onClick={handleRemoveIcon} style={{display: 'flex', alignItems: 'center'}}>
                                                                                <ClearIcon style={{fontSize: '1.1rem', marginLeft: 15}}/>
                                                                            </Box>
                                                                        ) : null
                                                                    }
                                                                </div>
                                                                {
                                                                    errorMsg.length > 0 ? (
                                                                        <Typography className={classes.error}>{errorMsg}</Typography>
                                                                    ) : null
                                                                }
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                    <div className={clsx(classes.content)}>
                                                        <Link
                                                            to={`/assessment-comment/update/${attribute.assessment_comment_id}`}
                                                            className={classes.btnSecondary}
                                                        >
                                                            Cancel
                                                        </Link>
                                                        <Button
                                                            className={classes.btnPrimary}
                                                            type="submit"
                                                        >
                                                            Update
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <RunDashedLogo />
                        </Grid>
                    </Grid>
            }
        </Container>
    )
};

let mapStateToProps = (state) => {
    return {
        error: state.ui.assessmentComments.error,
        loading: state.ui.assessmentComments.selectedAttributeLoading,
        attribute: state.assessmentComments.selectedCommentAttributeData,
    }
};

export default connect(mapStateToProps)(UpdateCommentAttribute);